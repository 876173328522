import { createContext, useState } from 'react'
import type { FC, ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'
import { theme } from '../theme'
import { WIMMELWOBRAZ_ID } from '../utils/constants'

type Topic = { id: string; title: string; color: string }
type Category = { id: string; title: string }
type SubCategory = { id: string; title: string }
export interface SearchParamsContextValue {
  searchParams: URLSearchParams
  removeParam: (key: SearchParamKey) => void
  activeTopic: Topic | null
  activeCategory: Category | null
  activeSubCategory: SubCategory | null
  setActiveTopic: (id: string, title: string, color: string | null) => void
  setActiveCategory: (id: string, title: string) => void
  setActiveSubCategory: (id: string, title: string) => void
  clearParams: () => void
}

interface SearchParamsProviderProps {
  children?: ReactNode
}

const SearchParamsContext = createContext<SearchParamsContextValue>({
  searchParams: new URLSearchParams(),
  activeTopic: null,
  activeCategory: null,
  activeSubCategory: null,
  removeParam: () => {},
  setActiveTopic: () => {},
  setActiveCategory: () => {},
  setActiveSubCategory: () => {},
  clearParams: () => {}
})

const clearSearchParams = (newParam: SearchParamKey, allParams: [SearchParamKey, string][]) => {
  const clearedParams = [...allParams]

  switch (newParam) {
    case 'tema':
      return clearedParams.filter((value) => value[0] !== 'category' && value[0] !== 'subcategory')
    case 'category':
      return clearedParams.filter((value) => value[0] !== 'subcategory')
    default:
      return clearedParams
  }
}

export const SearchParamsProvider: FC<SearchParamsProviderProps> = ({ children }) => {
  const [oldSearchParams, setSearchParams] = useSearchParams()
  const [activeTopic, setActiveTop] = useState<Topic | null>(null)
  const [activeCategory, setActiveCat] = useState<Category | null>(null)
  const [activeSubCategory, setActiveSubCat] = useState<SubCategory | null>(null)

  const clearParams = () => {
    setSearchParams([])
    setActiveTop(null)
    setActiveCat(null)
    setActiveSubCat(null)
  }

  const setParam = (key: SearchParamKey, value: string) => {
    let newParams: [SearchParamKey, string][] = []
    let found = false
    oldSearchParams.forEach((v, k) => {
      if (k === key) {
        found = true
        newParams.push([key, value])
      } else {
        newParams.push([k as SearchParamKey, v])
      }
    })

    if (!found) newParams.push([key, value])

    const clearedParams = clearSearchParams(key, newParams)

    setSearchParams(clearedParams)
  }

  const removeParam = (key: string) => {
    const newParams = new URLSearchParams(oldSearchParams)
    newParams.delete(key)
    setSearchParams(newParams)
  }

  const setActiveTopic = (id: string, title: string, color: string | null) => {
    if (id === '') {
      clearParams()
      return
    }

    // If we're on the wimmelwobraz route and switching to a different topic,
    // we need to navigate to the root first
    if (window.location.pathname === '/wimmelwobraz' && id !== WIMMELWOBRAZ_ID) {
      window.history.replaceState({}, '', '/')
    }

    setParam('tema', id)
    setActiveTop({ id, title, color: color ?? theme.palette.background.default })
    setActiveCat(null)
    setActiveSubCat(null)
  }

  const setActiveCategory = (id: string, title: string) => {
    if (id === '') {
      removeParam('category')
      setActiveCat(null)
      return
    }
    setParam('category', id)
    setActiveCat({ id, title })
    setActiveSubCat(null)
  }

  const setActiveSubCategory = (id: string, title: string) => {
    if (id === '') {
      removeParam('subcategory')
      setActiveSubCat(null)
      return
    }
    setParam('subcategory', id)
    setActiveSubCat({ id, title })
  }

  return (
    <SearchParamsContext.Provider
      value={{
        searchParams: oldSearchParams,
        activeTopic,
        activeCategory,
        activeSubCategory,
        setActiveTopic,
        setActiveCategory,
        setActiveSubCategory,
        removeParam,
        clearParams
      }}
    >
      {children}
    </SearchParamsContext.Provider>
  )
}

export const SearchParamsConsumer = SearchParamsContext.Consumer

export default SearchParamsContext
