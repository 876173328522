import { CategoryType } from 'src/types/categories'

export const CARD_SIZE = 250

export const SLOWNIK_ID = '54'
export const PARTNERS_ID = 'PARTNERS'
export const WIMMELWOBRAZ_ID = 'WIMMELWOBRAZY'

export const categories: {
  id: string
  info: {
    singularName: 'category'
    pluralName: 'categories'
    displayName: 'Category'
    description: ''
  }
  attributes: { title: string; type: CategoryType; imageSrc: string }
}[] = [
  {
    id: 'materials',
    info: {
      singularName: 'category',
      pluralName: 'categories',
      displayName: 'Category',
      description: ''
    },
    attributes: {
      title: 'Materialije',
      type: 'materials',
      imageSrc: 'https://data.dyrdomdej.de/public/images/stop_na_jednej_licbje_prehlad.jpg'
    }
  },
  {
    id: 'songs',
    info: {
      singularName: 'category',
      pluralName: 'categories',
      displayName: 'Category',
      description: ''
    },
    attributes: {
      title: 'Spĕwy',
      type: 'songs',
      imageSrc: 'https://data.dyrdomdej.de/public/images/hudzba_kartki_prehlad.jpg'
    }
  },
  {
    id: 'wordingLists',
    info: {
      singularName: 'category',
      pluralName: 'categories',
      displayName: 'Category',
      description: ''
    },
    attributes: {
      title: 'Słowne lisćiny',
      type: 'wordingLists',
      imageSrc: 'https://data.dyrdomdej.de/public/images/tajne_pismo_prehlad.jpg'
    }
  },
  {
    id: 'hiddenObjects',
    info: {
      singularName: 'category',
      pluralName: 'categories',
      displayName: 'Category',
      description: ''
    },
    attributes: {
      title: 'Prašaki',
      type: 'hiddenObjects',
      imageSrc: 'https://data.dyrdomdej.de/public/images/kotry_sad_to_je_prehlad.jpg'
    }
  },
  {
    id: 'videos',
    info: {
      singularName: 'category',
      pluralName: 'categories',
      displayName: 'Category',
      description: ''
    },
    attributes: {
      title: 'Bajki',
      type: 'videos',
      imageSrc: 'https://data.dyrdomdej.de/public/images/sadowe_mjezwoco_prehlad.jpg'
    }
  }
]
