import React from 'react'
import { Toolbar, Typography, Box, Button, IconButton, useTheme } from '@mui/material'
import { Home, Menu as MenuIcon, SearchOutlined } from '@mui/icons-material'
import { DashboardNavbarRoot } from './styled/Dashboard.styles'
import { useNavigate } from 'react-router-dom'

interface AppBarProps {
  onMenuClick: () => void
  onHomeClick: () => void
  isDesktop: boolean
  siteLogo: string
  logoLucija: string
  logoSst: string
  headerImage: string
}

export const AppBar: React.FC<AppBarProps> = ({
  onMenuClick,
  onHomeClick,
  isDesktop,
  siteLogo,
  logoLucija,
  logoSst,
  headerImage
}) => {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <DashboardNavbarRoot>
      <Toolbar sx={{ height: 64 }}>
        <Box>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={onMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='home'
            onClick={onHomeClick}
          >
            <Home />
          </IconButton>
        </Box>
        <Box sx={{ display: 'block', flexGrow: 1, ml: 2 }} />
        <Button onClick={onHomeClick}>
          <img src={siteLogo} alt='logo' height={isDesktop ? 40 : 30} />
        </Button>
        <Box sx={{ flexGrow: 1, ml: 2 }} />
        <Box sx={{ ml: 2, mr: 2 }}>
          <Button
            variant='text'
            onClick={() => navigate('/pytac')}
            endIcon={<SearchOutlined htmlColor='white' />}
          >
            <Typography color='white'>Pytać</Typography>
          </Button>
        </Box>
        {isDesktop && (
          <Box>
            <a href='https://lucija.de' style={{ marginRight: 10 }}>
              <img src={logoLucija} alt='logo' height={40} />
            </a>
            <a href='https://www.sorbischer-schulverein.de/hs' style={{ marginRight: 1 }}>
              <img src={logoSst} alt='logo' height={40} />
            </a>
          </Box>
        )}
      </Toolbar>
      <img
        src={headerImage}
        alt='header_line'
        height={20}
        style={{ objectFit: 'cover', zIndex: 3, position: 'absolute', top: 54 }}
      />
    </DashboardNavbarRoot>
  )
}
