import { useQuery } from '@tanstack/react-query'
import { fetchMenuPoints } from '../lib/requests/fetch-menu-points'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import useSearchParamsHelper from './use-search-params'

export const useMenuPoints = () => {
  const menuPoints = useQuery(['menuPoints'], () => fetchMenuPoints())
  const [params] = useSearchParams()
  const {
    activeTopic,
    activeCategory,
    activeSubCategory,
    setActiveTopic,
    setActiveCategory,
    setActiveSubCategory
  } = useSearchParamsHelper()

  const getMenuPoints = (topicId: string | null, categoryId: string | null) => {
    if (menuPoints?.isFetched) {
      if (!topicId) return menuPoints.data?.data.topics || []

      if (!categoryId)
        return menuPoints.data?.data?.topics?.find((d) => d.id === topicId)?.categories || []

      if (categoryId)
        return (
          menuPoints.data?.data?.topics
            ?.find((d) => d.id === topicId)
            ?.categories.find((category) => category.id === categoryId)?.subCategories || []
        )
    }
    return []
  }

  useEffect(() => {
    params.forEach((value, key) => {
      if (key === 'tema') {
        const foundTopic = menuPoints.data?.data.topics.find((d) => d.id === value)
        if (foundTopic) {
          setActiveTopic(value, foundTopic.title, foundTopic.color)
        }
      } else if (key === 'category') {
        if (params.get('tema')) {
          const foundCategory = getMenuPoints(params.get('tema'), null).find((d) => d.id === value)

          if (foundCategory) {
            setActiveCategory(value, foundCategory.title)
          }
        }
      } else if (key === 'subcategory') {
        if (params.get('tema') && params.get('category')) {
          const foundSubCategory = getMenuPoints(params.get('tema'), params.get('category')).find(
            (d) => d.id === value
          )
          if (foundSubCategory) setActiveSubCategory(value, foundSubCategory.title)
        }
      }
    })
  }, [menuPoints.isFetched])

  return {
    menuPoints,
    getMenuPoints,
    activeTopic,
    activeCategory,
    activeSubCategory,
    setActiveTopic,
    setActiveCategory,
    setActiveSubCategory
  }
}
