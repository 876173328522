import React from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Divider,
  useTheme
} from '@mui/material'
import { ChevronLeft, ChevronRight, ExpandLess } from '@mui/icons-material'
import { DrawerHeader } from './styled/Dashboard.styles'
import { drawerWidth } from './styled/Dashboard.styles'
import { useNavigate } from 'react-router-dom'
import { WIMMELWOBRAZ_ID, PARTNERS_ID } from '../utils/constants'

interface NavigationDrawerProps {
  isOpen: boolean
  onClose: () => void
  menuPoints: any // TODO: Add proper type
  activeTopic: any // TODO: Add proper type
  menuOpened: boolean
  setMenuOpened: (value: boolean) => void
  setActiveTopic: (id: string, title: string, color: string) => void
  setActiveCategory: (id: string, title: string) => void
  setActiveSubCategory: (id: string, title: string) => void
  getMenuPoints: (topicId: string | null, categoryId: string | null) => any[]
}

export const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  isOpen,
  onClose,
  menuPoints,
  activeTopic,
  menuOpened,
  setMenuOpened,
  setActiveTopic,
  setActiveCategory,
  setActiveSubCategory,
  getMenuPoints
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const handleTopicClick = (topic: any) => {
    if (topic.id === WIMMELWOBRAZ_ID) {
      setActiveTopic(topic.id, topic.title, topic.color)
      setActiveCategory('', '')
      setActiveSubCategory('', '')
      setMenuOpened(true)
    } else if (topic.id === PARTNERS_ID) {
      setActiveTopic(topic.id, topic.title, topic.color)
      setActiveCategory('', '')
      setActiveSubCategory('', '')
      setMenuOpened(false)
      navigate('/partnerojo')
    } else {
      setMenuOpened(true)
      setActiveTopic(topic.id, topic.title, topic.color)
      navigate('/')
    }
  }

  return (
    <Drawer
      sx={{
        width: isOpen ? drawerWidth : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant='persistent'
      anchor='left'
      open={isOpen}
    >
      <DrawerHeader>
        <IconButton onClick={onClose}>
          {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {menuPoints?.data?.data?.topics
          ?.sort((a: any, b: any) => a.sortingKey - b.sortingKey)
          .map((topic: any) => (
            <ListItem
              key={topic.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <ListItemButton
                sx={{
                  width: '100%',
                  backgroundColor: activeTopic?.id === topic.id ? 'rgba(0,0,0,0.1)' : 'inherit'
                }}
                onClick={() => handleTopicClick(topic)}
              >
                <ListItemText primary={topic.title} />
                {activeTopic?.id === topic.id && menuOpened && (
                  <ExpandLess
                    onClick={(e) => {
                      setMenuOpened(false)
                      e.stopPropagation()
                    }}
                  />
                )}
              </ListItemButton>
              {activeTopic?.id === topic.id && menuOpened && (
                <List sx={{ width: '100%' }}>
                  {getMenuPoints(topic.id, null).map((category) => (
                    <ListItem
                      key={category.id}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <ListItemButton
                        sx={{ width: '100%' }}
                        onClick={() => {
                          if (topic.id === WIMMELWOBRAZ_ID) {
                            navigate(`/wimmelwobraz/${category.id}`)
                          } else {
                            setActiveCategory(category.id, category.title)
                          }
                        }}
                      >
                        <ListItemText primary={category.title} />
                      </ListItemButton>
                      {activeTopic?.id === topic.id && topic.id !== WIMMELWOBRAZ_ID && (
                        <List sx={{ width: '100%' }}>
                          {getMenuPoints(topic.id, category.id).map((subCategory) => (
                            <ListItem key={subCategory.id}>
                              <ListItemButton
                                sx={{ width: '100%' }}
                                onClick={() => {
                                  setActiveSubCategory(subCategory.id, subCategory.title)
                                }}
                              >
                                <ListItemText primary={subCategory.title} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </ListItem>
                  ))}
                </List>
              )}
            </ListItem>
          ))}
        <ListItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <ListItemButton
            sx={{
              width: '100%',
              backgroundColor: activeTopic?.id === PARTNERS_ID ? 'rgba(0,0,0,0.1)' : 'inherit'
            }}
            onClick={() => handleTopicClick({ id: PARTNERS_ID, title: 'Partnerojo', color: null })}
          >
            <ListItemText primary='Partnerojo' />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}
