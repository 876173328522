import { useIsFetching } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useNavigate, useRoutes } from 'react-router-dom'
import { LoadingSpinner } from './components/loading-spinner'
import { defaultRoute } from './routes/route'
import { Footer } from './components/footer'
import { Box, useTheme } from '@mui/material'
import { Main } from './components/styled/Dashboard.styles'
import { AppBar } from './components/AppBar'
import { NavigationDrawer } from './components/NavigationDrawer'
import { useMenuPoints } from './hooks/useMenuPoints'
import useSearchParamsHelper from './hooks/use-search-params'

const headerImage = require('./images/header_line.png')
const backgroundStructure = require('./images/background-structure.png')
const siteLogo = require('./images/site_logo.png')
const logoLucija = require('./images/logo_lucija.png')
const logoSst = require('./images/sst_logo.png')

function App() {
  const theme = useTheme()
  const content = useRoutes(defaultRoute)
  const isFetching = useIsFetching()
  const navigate = useNavigate()
  const [drawerOpened, setDrawerOpened] = useState(false)
  const [menuOpened, setMenuOpened] = useState(false)
  const isDesktop = window.innerWidth >= 1024
  const { clearParams } = useSearchParamsHelper()

  const {
    menuPoints,
    getMenuPoints,
    activeTopic,
    activeCategory,
    activeSubCategory,
    setActiveTopic,
    setActiveCategory,
    setActiveSubCategory
  } = useMenuPoints()

  const handleHomeClick = () => {
    clearParams()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate('/')
  }

  return (
    <div
      style={{
        background: activeTopic ? activeTopic.color : theme.palette.background.default,
        minHeight: 'calc(100vh - 64px)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <img
        style={{
          opacity: 0.1,
          position: 'fixed',
          left: 0,
          top: 0,
          height: '100%',
          width: '100%',
          pointerEvents: 'none'
        }}
        src={backgroundStructure}
      />
      <AppBar
        onMenuClick={() => setDrawerOpened(true)}
        onHomeClick={handleHomeClick}
        isDesktop={isDesktop}
        siteLogo={siteLogo}
        logoLucija={logoLucija}
        logoSst={logoSst}
        headerImage={headerImage}
      />

      <NavigationDrawer
        isOpen={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        menuPoints={menuPoints}
        activeTopic={activeTopic}
        menuOpened={menuOpened}
        setMenuOpened={setMenuOpened}
        setActiveTopic={setActiveTopic}
        setActiveCategory={setActiveCategory}
        setActiveSubCategory={setActiveSubCategory}
        getMenuPoints={getMenuPoints}
      />

      <Main
        sx={{
          marginTop: '64px',
          paddingBottom: 6
        }}
        open={drawerOpened}
      >
        {content}
      </Main>
      <div style={{ marginLeft: -10 }}>
        <Footer />
      </div>

      <LoadingSpinner isVisible={isFetching > 0} />
    </div>
  )
}

export default App
